/**
 *
 * @description products.js
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 18 July 2020
 *
 */

import * as React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Grid, Card, Button, Box, LinearProgress, Typography } from '@material-ui/core'
import { Carousel } from 'react-responsive-carousel'

export default function Products() {
  const [products, setProducts] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    async function fetchProducts() {
      setLoading(true)
      const res = await fetch(
        'https://api.almagsb.com/wp/wp-json/wc/v3/products?consumer_key=ck_ecb763e8eb8043969c90d8a46b47e195ea9ab06f&consumer_secret=cs_80f79b2c9806bf769bdea54d376229e4663afa64',
        {
          method: 'GET',
        }
      )
        .then(res => res.json())
        .catch(err => {
          setLoading(false)
          console.log('err', err)
        })

      if (res) {
        setProducts(res)
      }

      setLoading(false)
    }

    fetchProducts()
  }, [])

  return (
    <Layout>
      <SEO title='Products | Almag Sdn. Bhd.' />

      <Box marginBottom={4} marginTop={4}>
        {loading && <LinearProgress />}
        {!loading && products.length === 0 && (
          <Typography variant='h3'>
            <i>Coming Soon, stay tuned</i>
          </Typography>
        )}
        {products.length > 0 && (
          <Grid container spacing={2}>
            {products &&
              products.map(product => (
                <Grid item xs={12} sm={4} key={product.id}>
                  <Card>
                    <Carousel showStatus={false} showThumbs={false} emulateTouch={true} infiniteLoop>
                      {product &&
                        product.images &&
                        product.images.map((productImage, index) => (
                          <div key={`${productImage}-${index}`}>
                            <img
                              style={{ height: 200, objectFit: 'contain' }}
                              src={productImage.src}
                              alt={product.name}
                            />
                          </div>
                        ))}
                    </Carousel>
                    <Box padding={2} textAlign='center'>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={async () => {
                          await localStorage.setItem('@product', product.id)
                          if (window) {
                            window.location.href = '/enquiry'
                          }
                        }}
                      >
                        {product.name}
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              ))}
          </Grid>
        )}
      </Box>
    </Layout>
  )
}
